import { getTodayTimestamp, getWeekSeconds, Timestamp } from "@kanpla/system";
import moment from "moment";

type Props = {
  dateSeconds: number;
};

export const getRelativeDateFormat = (
  { dateSeconds }: Props,
  dictionaries: { [key: string]: string }
) => {
  const todaySeconds = getTodayTimestamp({ Timestamp }).seconds;
  const dateWeekSeconds = getWeekSeconds(dateSeconds);
  const weekSeconds = getWeekSeconds(todaySeconds);

  // Calculate difference in days and weeks
  const diffDays = Math.floor((dateSeconds - todaySeconds) / 86400);
  const diffWeeks = (dateWeekSeconds - weekSeconds) / 604800;

  // Calculate human-friendly conditions
  const isToday = diffDays === 0;
  const isTomorrow = diffDays === 1;
  const isYesterday = diffDays === -1;
  const isThisWeek = diffWeeks === 0;
  const isNextWeek = diffWeeks === 1;
  const isLastWeek = diffWeeks === -1;
  //   const isFarFuture = diffWeeks > 1;
  //   const isFarPast = diffWeeks < -1;

  // Get moment day formats
  const date = moment.unix(dateSeconds);
  const dayName = date.format("dddd");
  const calendarFormat = date.format("dddd D/M");

  // Return formats
  if (isToday) return `${dictionaries.today} (${dayName})`;
  if (isTomorrow) return `${dictionaries.tomorrow} (${dayName})`;
  if (isYesterday) return `${dictionaries.yesterday} (${dayName})`;
  if (isThisWeek) return `${dictionaries.this} ${dayName}`;
  if (isNextWeek) return `${dictionaries.next} ${dayName}`;
  if (isLastWeek) return `${dictionaries.last} ${dayName}`;
  return calendarFormat;
};
