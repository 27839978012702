import classNames from "classnames";
import React, { InputHTMLAttributes, ReactElement, useRef } from "react";
import { useMergeRefs } from "rooks";
import { BaseInputProps } from "./BaseInput";

interface BaseRadioInputProps
  extends Omit<
    BaseInputProps,
    "value" | "onChange" | "design" | "label" | "color"
  > {
  value: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  labelLeft?: boolean;
  children: React.ReactNode;
}

type RadioInputProps = BaseRadioInputProps &
  Partial<
    Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "type" | "value" | "onChange" | "children" | "checked"
    >
  >;

const BaseRadio = React.forwardRef<any, RadioInputProps>(
  (props, ref): JSX.Element => {
    const {
      value = null,
      onChange = null,
      className = "",
      disabled = true,
      labelLeft = false,
      defaultChecked = false,
      id = null,
      dataCy,
      children,
      ...rest
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const inputRefs = useMergeRefs(ref, inputRef);

    return (
      <label
        className={classNames(
          "flex items-center mb-2",
          disabled ? "cursor-not-allowed" : "cursor-pointer",
          className
        )}
        htmlFor={id || `radio-button-${value}`}
      >
        <span
          className={classNames("form-label text-base ml-2", {
            "order-last": !labelLeft,
          })}
        >
          {children}
        </span>
        <input
          {...rest}
          data-cy={dataCy}
          id={id || `radio-button-${value}`}
          value={value}
          onChange={onChange}
          disabled={disabled}
          defaultChecked={defaultChecked}
          type="radio"
          ref={inputRefs}
          className="radio-input-primary"
        />
      </label>
    );
  }
);

interface RadioGroupProps {
  label?: string;
  options: Array<RadioOption>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
  display?: "horizontal" | "vertical";
  required?: boolean;
  defaultValue?: string | number;
  name?: string;
  disabled?: boolean;
}

export type RadioOption = {
  label: string | ReactElement;
  value: string | number;
  disabled?: boolean;
  dataCy?: string;
};

const Group: React.FC<RadioGroupProps> = React.forwardRef(
  (props, ref): JSX.Element => {
    const {
      label,
      options,
      onChange,
      value,
      display = "horizontal",
      required = false,
      defaultValue = null,
      name,
      ...rest
    } = props;

    const _defaultValue = defaultValue || value || null;

    return (
      <fieldset>
        {label && (
          <legend className="form-label">
            {required ? (
              <span className="font-medium mr-1 text-danger-main">*</span>
            ) : null}{" "}
            {label}
          </legend>
        )}
        <div
          className={classNames({
            "inline-flex items-center": display === "horizontal",
          })}
        >
          {options.map(({ label, disabled = false, dataCy, value }, i) => {
            const shortenedLabel =
              typeof label === "string" ? label.replace(/\s+/g, "") : label;
            const optionId = `radio-button-${shortenedLabel}-${i}`;

            return (
              <Radio
                id={optionId}
                key={i}
                value={value}
                defaultChecked={
                  _defaultValue ? _defaultValue === value : i === 0
                }
                dataCy={dataCy}
                disabled={disabled}
                onChange={onChange}
                className={classNames({
                  "mr-2 mb-2": display === "horizontal",
                })}
                ref={ref}
                {...rest}
                name={name}
              >
                {label}
              </Radio>
            );
          })}
        </div>
      </fieldset>
    );
  }
);

type CompoundedRadioType = typeof BaseRadio & {
  Group: typeof Group;
};

(BaseRadio as CompoundedRadioType).Group = Group;

export const Radio = BaseRadio as CompoundedRadioType;
