import { dateToMomentUtc, useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { Calendar } from "./Primitive";

const isDayInRange = (target: Date, start: Date, end: Date) => {
  return moment(target).isBetween(moment(start), moment(end), "day", "[]");
};

export type MomentRange = [Moment | null, Moment | null];

export interface WeekPickerProps {
  /** The first day of the selected week */
  value: Moment;
  /** Array of Moment objects, containig the start of the week and the end of the week */
  onChange: (newValue: MomentRange) => void;
  /** Adds a button to allow the user to quicly go back to current week (`true` by default) */
  withCurrentWeekButton?: boolean;
}

const WeekPicker = (props: WeekPickerProps): JSX.Element => {
  const { value, onChange, withCurrentWeekButton = true } = props;
  const t = useT();

  const defaultWeekStartDate =
    moment(value).startOf("week") || moment().startOf("week");
  const defaultWeekEndDate =
    moment(value).add(6, "days") || moment().endOf("week");

  // Keep track of the hovered weeks
  const [hoveredWeek, setHoveredWeek] = useState<{
    from: null | Date;
    to: null | Date;
  }>({ from: null, to: null });

  const [selectedWeek, setSelectedWeek] = useState<{
    from: Date;
    to: Date;
  }>({
    from: defaultWeekStartDate.toDate(),
    to: defaultWeekEndDate.toDate(),
  });

  const handleWeekClick = (day: Date) => {
    // Calculate the start and end dates of the clicked week
    const weekStart = moment(day).startOf("week").toDate();
    const weekEnd = moment(weekStart).add(6, "days").toDate();

    // Update the selected week
    setSelectedWeek({ from: weekStart, to: weekEnd });

    const utcDate = dateToMomentUtc(day);
    onChange([moment(utcDate).startOf("week"), moment(utcDate).endOf("week")]);
  };

  const handleDayMouseEnter = (day: Date) => {
    // Calculate the start and end dates of the hovered week
    const weekStart = moment(day).startOf("week").toDate();
    const weekEnd = moment(weekStart).add(6, "days").toDate();

    // Update the hovered week
    setHoveredWeek({ from: weekStart, to: weekEnd });
  };

  const handleDayMouseLeave = () => {
    // Reset the hovered week when the mouse leaves a day
    setHoveredWeek({ from: null, to: null });
  };

  const isDayBetweenSelectedWeek = (day: Date) =>
    isDayInRange(day, selectedWeek.from, selectedWeek.to);

  const isDayInHoveredWeek = (day: Date) => {
    return (
      !isDayBetweenSelectedWeek(day) &&
      ((hoveredWeek.from && hoveredWeek.to) || false) &&
      isDayInRange(day, hoveredWeek.from, hoveredWeek.to)
    );
  };

  const isDayInSelectedWeek = (day: Date) => {
    return (
      selectedWeek.from && selectedWeek.to && isDayBetweenSelectedWeek(day)
    );
  };

  return (
    <Calendar
      showToday={false}
      onDayClick={handleWeekClick}
      onDayMouseEnter={handleDayMouseEnter}
      onDayMouseLeave={handleDayMouseLeave}
      modifiers={{
        selectedWeek: isDayInSelectedWeek,
        hoveredWeek: isDayInHoveredWeek,
      }}
      defaultMonth={value.toDate()}
      weekMode
      modifiersClassNames={{
        selectedWeek:
          "!bg-primary-backdrop cursor-default !text-primary-main [&:has([aria-selected]):nth-child(2)]:!rounded-l-md [&:has([aria-selected]):nth-child(8)]:!rounded-r-md",
        hoveredWeek: "!bg-secondary-light !text-secondary-contrast",
      }}
      selected={[
        selectedWeek.from,
        { from: selectedWeek.from, to: selectedWeek.to },
      ]}
      showWeekNumber
      footer={
        withCurrentWeekButton && (
          <Button
            size="small"
            className="w-full mt-3"
            onClick={() => handleWeekClick(moment().startOf("week").toDate())}
          >
            {t("To the current week")}
          </Button>
        )
      }
    />
  );
};

export { WeekPicker };
