import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Timestamp,
  addWeek,
  getWeekArray,
  subtractWeek,
  tx,
  useT,
  weekToDisplayString,
} from "@kanpla/system";
import { DayIndex, Week } from "@kanpla/types";
import { Calendar, Popover, PopoverContent, PopoverTrigger } from "@kanpla/ui";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { WeekDayButtons } from "./WeekDayButtons";

interface Props {
  week: Week;
  dayIndex: DayIndex;
  setDate: ({ week, dayIndex }: { week?: Week; dayIndex?: DayIndex }) => void;
  dayRowOnPhone: boolean;
  dayRowVisibleOnTablet?: boolean;
}

export const WeekNavbarSwitch = ({
  week,
  dayRowOnPhone,
  setDate,
  dayIndex: selectedDayIndex,
  dayRowVisibleOnTablet = false,
}: Props) => {
  moment?.updateLocale(tx.getCurrentLocale(), {
    week: { dow: 1, doy: 6 },
  });

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const t = useT();

  const buttonStyles =
    "px-6 h-auto flex items-center justify-center rounded hover:bg-main-600 transition-all flex-grow-0 flex-shrink-0";

  const adjustWeek = (ahead: boolean) => {
    const newWeek = ahead ? addWeek(week) : subtractWeek(week);

    return setDate({ week: newWeek, dayIndex: 0 });
  };

  return (
    <>
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <div className="justify-between border rounded-lg bg-main-100 items-stretch flex w-full md:w-auto">
          <button className={buttonStyles} onClick={() => adjustWeek(false)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <PopoverTrigger>
            <button className="block text-center mx-1 md:mx-2 relative rounded hover:bg-main-600 transition-all flex-grow cursor-pointer md:w-40 px-2 py-2">
              <div className="text-lg font-semibold text-primary-main whitespace-nowrap my-1 text-center">
                {weekToDisplayString(week, t)}
              </div>
            </button>
          </PopoverTrigger>

          <button className={buttonStyles} onClick={() => adjustWeek(true)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <PopoverContent className="w-fit flex justify-center items-center">
          <Calendar.WeekPicker
            value={moment.utc(week[0].seconds * 1000)}
            onChange={(newValue) => {
              const weekStart = newValue[0] as Moment;
              const newSeconds =
                weekStart.utc().startOf("week").valueOf() / 1000;
              const newWeek = getWeekArray(newSeconds, Timestamp);

              setDate({ week: newWeek, dayIndex: selectedDayIndex });
              setPopoverOpen(false);
            }}
          />
        </PopoverContent>
      </Popover>
      {/* Display days as a row when the module flow is "menuPreview" */}
      {dayRowOnPhone && (
        <WeekDayButtons
          week={week}
          selectedDayIndex={selectedDayIndex}
          onDayClick={(newDayIndex) => setDate({ dayIndex: newDayIndex, week })}
          visibleForTablet={dayRowVisibleOnTablet}
        />
      )}
    </>
  );
};
