import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { message } from "@kanpla/ui";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

export type InputOperation = "increment" | "decrement";

interface Props {
  amount: number; // state value
  setAmount: (newAmount: number) => void; // function to setState
  disabled?: boolean;
  minAmount?: number;
  maxAmount?: number;
  className?: string;
  size?: "small" | "medium";
  hapticCallback?: () => void;
  stopPropagation?: boolean;
  design?: "default" | "white" | "soft";
  maxDisabled?: boolean;
}

export const InputAmount = (props: Props) => {
  const {
    amount = 0,
    setAmount,
    disabled = false,
    minAmount = 0,
    maxAmount = Infinity,
    className,
    size = "medium",
    hapticCallback = () => null,
    stopPropagation = false,
    design = "default",
    maxDisabled: maxDisabledProp = false,
  } = props;
  const [value, setValue] = useState(amount); // input field value, can be NaN

  const t = useT();

  useEffect(() => {
    setValue(amount);
  }, [amount]);

  const updateAmount = (change: number) => {
    const oldAmount = isNaN(amount) ? 0 : amount;
    let newAmount = oldAmount + change;
    if (newAmount < minAmount) newAmount = minAmount;
    if (newAmount > maxAmount) newAmount = maxAmount;
    setAmount(newAmount);
  };

  const buttonStyles = classNames([
    size === "small" ? "px-2" : "px-4",
    "h-full transform m-0",
    { "text-black hover:bg-slate-100": design === "white" },
    { "text-primary-dark": design === "soft" },
    {
      "text-secondary-contrast disabled:text-secondary-light hover:bg-secondary-light disabled:hover:bg-transparent":
        design === "default",
    },
  ]);

  const minDisabled = disabled || amount <= (minAmount || 0);
  const maxDisabled = maxDisabledProp || disabled || amount >= maxAmount;
  const height = size === "small" ? 30 : 40;

  const inputClasses = classNames(
    design === "default" ? " text-primary-main" : "text-black bg-white",
    {
      "text-lg py-2 font-medium rounded text-center mx-1": true,
      "h-full": design !== "soft",
      "h-[30px]": design === "soft",
      "w-12": size === "medium",
      "w-10": size === "small",
    }
  );

  return (
    <div
      style={{ height }}
      className={classNames(
        "rounded-md",
        {
          "bg-secondary-main border-secondary-main border-4":
            design === "default",
        },
        { "bg-white px-1 py-1.5": design === "white" },
        { "bg-primary-backdrop text-primary-dark": design === "soft" },
        "box-content inline-flex items-center",
        className
      )}
    >
      <button
        className={classNames(
          "rounded-md transition-colors",
          buttonStyles,
          { "text-text-disabled": design === "default" && minDisabled },
          { "text-gray-300": design === "white" && minDisabled },
          {
            "cursor-default pointer-events-none": minDisabled,
          }
        )}
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }

          if (minDisabled) return;

          hapticCallback();
          updateAmount(-1);
        }}
        disabled={minDisabled}
        type="button"
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <input
        disabled={disabled}
        className={inputClasses}
        type="number"
        pattern="[0-9]*"
        inputMode="numeric"
        min={minAmount}
        value={value}
        max={maxAmount}
        onFocus={(e) => e.currentTarget.select()}
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
        }}
        onChange={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }

          const newAmount = parseInt(e.target.value);
          if (newAmount > maxAmount) {
            setValue(maxAmount);
            setAmount(maxAmount);
            message.error(
              t("There are only {value} products left", {
                value: maxAmount,
              })
            );
            return;
          }
          setValue(newAmount);
          if (isNaN(newAmount)) return;
          setAmount(newAmount);
        }}
        onBlur={() => {
          setValue(amount);
        }}
      />
      <button
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }

          if (maxDisabled) return;

          hapticCallback();
          updateAmount(1);
        }}
        className={classNames(
          "transition-colors rounded-md",
          buttonStyles,
          {
            "cursor-default pointer-events-none": maxDisabled,
          },
          { "text-text-disabled": design === "default" && maxDisabled },
          { "text-gray-300": design === "white" && maxDisabled }
        )}
        disabled={maxDisabled}
        type="button"
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
};
