import { DayIndex, Timestamp, Week } from "@kanpla/types";
import classnames from "classnames";
import moment, { Moment } from "moment";
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";

export interface WeekDayButtonsProps {
  week: Week;
  selectedDayIndex: DayIndex;
  onDayClick: (newDayIndex: DayIndex) => void;
  dayNumberMapper?: (dayNumber: string, dayIndex: DayIndex) => string | null;
  dayNameMapper?: (
    dayName: string,
    dayIndex: DayIndex
  ) => string | React.ReactNode | null;
  contentMapper?: (
    daySeconds: number,
    dayIndex: DayIndex,
    momentObj: Moment
  ) => ReactNode;
  visibleForTablet?: boolean;
  /** Control gray out dates if they are in the past */
  disablePastDateUi?: boolean;
}

/** Hidden for MD size. */
export const WeekDayButtons: FC<WeekDayButtonsProps> = ({
  week,
  selectedDayIndex,
  onDayClick,
  contentMapper,
  visibleForTablet = false,
  disablePastDateUi,
}) => {
  const [today, thisMonth] = useMemo(
    () => [new Date(Date.now()).getDate(), new Date(Date.now()).getMonth()],
    []
  );

  // see whether a day is in the past or not
  const isDayInThePast = useCallback(
    (day: Timestamp) => {
      const dayDate = new Date(day.toMillis());
      const dayMonth = dayDate.getMonth();

      const isDaySmaller = today > dayDate.getDate() && thisMonth >= dayMonth;
      return isDaySmaller || thisMonth > dayMonth;
    },
    [thisMonth, today]
  );

  const weekArray = useMemo(
    () =>
      week.map((day, index) => {
        const daySeconds = day.seconds;
        const dayIndex = index as DayIndex;
        const momentObj = moment.unix(daySeconds);
        const content = contentMapper?.(daySeconds, dayIndex, momentObj);

        return {
          dayIndex,
          daySeconds,
          isInThePast: isDayInThePast(day),
          isSelectedDay: selectedDayIndex === index,
          weekDayNumber: momentObj.format("D"),
          weekDayName: momentObj.format("ddd"),
          content,
        };
      }),
    [week, isDayInThePast, selectedDayIndex, contentMapper, moment.locale()]
  );

  const visibleForTabletClasses = useMemo(
    () =>
      classnames(
        visibleForTablet ? "lg:hidden" : "md:hidden",
        "flex w-full content-center justify-center -mb-4 z-30"
      ),
    [visibleForTablet]
  );

  return (
    <div className={visibleForTabletClasses}>
      {weekArray.map(
        ({
          daySeconds,
          dayIndex,
          isInThePast,
          weekDayNumber,
          weekDayName,
          content,
        }) => {
          return (
            <div
              className={classnames(
                "py-1 px-2 mt-3 flex flex-col items-center cursor-pointer transition rounded-md flex-1 max-w-[68px]",
                isInThePast && !disablePastDateUi && "text-text-disabled",
                isInThePast &&
                  !disablePastDateUi &&
                  selectedDayIndex === dayIndex
                  ? "bg-background-secondary text-text-contrast"
                  : selectedDayIndex === dayIndex
                  ? "bg-primary-main shadow-md text-primary-contrast"
                  : "bg-transparent"
              )}
              onClick={() => onDayClick(dayIndex)}
              key={dayIndex}
            >
              {content === null || content ? (
                content
              ) : (
                <>
                  <span className="text-xl font-semibold mt-1">
                    {weekDayNumber}
                  </span>
                  <span className="text-sm opacity-75 mb-2 break-word text-center">
                    {weekDayName}
                  </span>
                </>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};
