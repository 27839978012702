import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCalendarLocale } from "@kanpla/system";
import classnames from "classnames";
import React from "react";
import { DayPicker } from "react-day-picker";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  weekMode?: boolean;
  showToday?: boolean;
};

const Calendar: React.FC<CalendarProps> = ({
  className = "",
  classNames,
  showOutsideDays = true,
  mode = "single",
  weekStartsOn = 1,
  weekMode = false,
  showToday = true,
  ...props
}) => {
  const { locale } = useCalendarLocale();

  return (
    // @ts-ignore
    <DayPicker
      mode={mode}
      weekStartsOn={weekStartsOn}
      showOutsideDays={showOutsideDays}
      locale={locale}
      className={classnames(
        "kanpla-calendar p-3 text-text-primary bg-background-primary",
        className
      )}
      modifiersClassNames={{
        range_start: "calendar-range-start",
        range_end: "calendar-range-end",
        outside: "calendar-day-outside",
      }}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "md:text-sm text-lg font-medium",
        nav: "space-x-1 flex items-center",
        nav_button:
          "h-10 w-10 md:h-7 md:w-7 bg-transparent p-0 border border-secondary-light rounded-md hover:bg-secondary-light hover:text-secondary-contrast transition-all ease-in-out disabled:bg-transparent disabled:border-none disabled:hover:bg-transparent disabled:!text-text-disabled disabled:opacity-100 disabled:cursor-not-allowed",
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-text-disabled rounded-md w-11 md:w-9 font-normal text-lg md:text-sm uppercase",
        row: "flex w-full mt-2",
        cell: classnames(
          mode === "range" && "[&:has([aria-selected])]:bg-primary-backdrop",
          "relative text-center text-lg md:text-sm p-0",
          weekMode
            ? "[&:has([aria-selected]):nth-child(2)]:!rounded-l-md [&:has([aria-selected]):nth-child(8)]:!rounded-r-md calendar-week-mode"
            : "first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
        ),
        day: classnames(
          "relative z-10 w-11 h-11 md:h-9 md:w-9 p-0 font-normal aria-selected:opacity-100 [&:not([aria-selected])]:hover:bg-secondary-light [&:not([aria-selected])]:hover:text-secondary-contrast",
          !weekMode && "[&:not([aria-selected])]:hover:rounded-md rounded-md"
        ),
        day_selected:
          "bg-primary-main text-primary-contrast hover:bg-primary-main hover:text-primary-contrast active:bg-primary-main active:text-primary-contrast focus:bg-primary-main focus:text-primary-contrast",
        day_range_middle:
          "aria-selected:bg-primary-backdrop aria-selected:text-primary-main ",
        day_hidden: "invisible",
        day_range_end: "bg-primary-main text-primary-contrast",
        day_range_start: "bg-primary-main text-primary-contrast",
        day_today: classnames(
          showToday
            ? "[&:not([aria-selected])]:text-secondary-contrast disabled:hover:!bg-secondary-light [&:not([aria-selected])]:bg-secondary-light"
            : ""
        ),
        day_disabled:
          "text-text-disabled opacity-50 hover:!bg-background-primary hover:text-text-disabled hover:opacity-50 cursor-not-allowed",
        weeknumber:
          "text-center text-secondary-light text-sm md:h-9 md:w-9 w-11 h-11 !flex justify-center items-center",
        ...classNames,
      }}
      components={{
        IconLeft: () => (
          <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4" />
        ),
        IconRight: () => (
          <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4" />
        ),
      }}
      {...props}
    />
  );
};

Calendar.displayName = "Calendar";

export { Calendar };
