import { OrderInfo } from "@kanpla/types";
import moment from "moment";

export const TIME_SEPARATOR = " : ";

export const getHours = (timeInputValue: OrderInfo["timeInput"]): number =>
  Math.floor(timeInputValue / 3600);

export const getMinutes = (timeInputValue: OrderInfo["timeInput"]): number =>
  Math.floor((timeInputValue % 3600) / 60) || 0;

export const getTimeOption = (hours: number, minutes: number): string => {
  return [
    hours.toString().padStart(2, "0"),
    TIME_SEPARATOR,
    minutes.toString().padStart(2, "0"),
  ].join("");
};

export const getAvailableTimeSlots = (
  startAt: number,
  endAt: number,
  interval: number,
  takeaway?: boolean
): string[] => {
  if (
    typeof startAt !== "number" ||
    typeof endAt !== "number" ||
    typeof interval !== "number"
  )
    return [];
  const intervalInMinutes = interval * 60;

  const allValues = [];
  let current = startAt;
  while (current <= endAt && allValues.length < 1440) {
    allValues.push(current);
    current = current + intervalInMinutes;
  }

  const nowFormat = moment().format("hh:mm");
  const [minutes, seconds] = nowFormat.split(":");
  const nowSeconds = (parseInt(minutes) * 60 + parseInt(seconds)) * 60;

  const options = allValues
    .filter((value) => !takeaway || value > nowSeconds)
    .map((value) => {
      const hours = getHours(value);
      const minutes = getMinutes(value);
      return getTimeOption(hours, minutes);
    });

  return options;
};
