import { Week } from "@kanpla/types";
import moment from "moment";
import { t } from "..";
import { getWeekSeconds } from "./getWeekSeconds";
import { timestampToMoment } from "./timestampToMoment";

export const weekToDisplayString = (week?: Week) => {
  const startDate = timestampToMoment(week?.[0]);
  const endDate = timestampToMoment(week?.[week?.length - 1]);

  const sameMonth = startDate?.format("M") === endDate?.format("M");

  const startDateFormatted = startDate?.format(sameMonth ? "D" : "DD/M");
  const endDateFormatted = endDate?.format("D/M");

  const genericFormat = `${t(
    "Week"
  )} ${startDate?.week()}: ${startDateFormatted}-${endDateFormatted}`;

  const currentSeconds = getWeekSeconds(moment().unix());
  const weeksAhead = ((week?.[0]?.seconds || 0) - currentSeconds) / 604800;
  const displayString =
    weeksAhead === 0
      ? t("This week")
      : weeksAhead === 1
      ? t("Next week")
      : genericFormat;

  return displayString;
};
