import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getMidnightSeconds,
  getNextWorkingDay,
  getPrevWorkingDay,
  getRelativeDateFormat,
  useT,
} from "@kanpla/system";
import { DayIndex, Module, Week, _FrontendModule } from "@kanpla/types";
import {
  Button,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@kanpla/ui";
import { getDay } from "date-fns";
import { capitalize } from "lodash";
import moment from "moment";
import { default as React, useCallback, useMemo } from "react";

interface Props {
  week: Week;
  dayIndex: DayIndex;
  setDate: ({ dateSeconds }: { dateSeconds: number }) => void;
  maxWidth?: number;
  confirm?: (
    currModule?: _FrontendModule | undefined
  ) => Promise<string | boolean>;
  module?: Module;
}

export const TodaySwitch = ({
  week,
  dayIndex,
  setDate,
  maxWidth = 330,
  confirm = async () => true,
  module,
}: Props) => {
  const t = useT();

  const dictionaries = {
    today: t("Today"),
    tomorrow: t("tomorrow").toUpperCase(),
    yesterday: t("yesterday").toUpperCase(),
    this: t("This"),
    next: t("Next"),
    last: t("last"),
  };

  const dateSeconds = week[dayIndex]?.seconds || 0;

  const date = useMemo(() => moment.unix(dateSeconds).utc(), [dateSeconds]);

  const displayDate = getRelativeDateFormat({ dateSeconds }, dictionaries);

  const includeWeekend = module?.plugins?.sevenDayWeek?.active;

  // Confirm
  const handleDateChange = async (dateSeconds: number) => {
    if (!date) return;

    await confirm();
    setDate({ dateSeconds });
  };

  const buttonStyles =
    "px-6 h-auto flex items-center justify-center rounded hover:bg-main-600 flex-grow-1 flex-shrink-0";

  const prevDay = useCallback(() => {
    if (!date) return;
    const prevWorkingDay = getPrevWorkingDay(date.unix(), includeWeekend);
    handleDateChange(prevWorkingDay);
  }, [date, includeWeekend]);

  const nextDay = useCallback(() => {
    if (!date) return;
    const nextWorkingDay = getNextWorkingDay(date.unix(), includeWeekend);
    handleDateChange(nextWorkingDay);
  }, [date, includeWeekend]);

  return (
    <Popover>
      <div
        className="justify-between items-stretch w-full flex border rounded-lg bg-main-100"
        style={{ maxWidth }}
      >
        <button className={buttonStyles} onClick={prevDay}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <PopoverTrigger>
          <button className="block text-center md:mx-2 relative rounded hover:bg-main-600 flex-grow cursor-pointer">
            <div className="text-lg font-semibold text-primary-main py-3">
              {capitalize(displayDate)}
            </div>
          </button>
        </PopoverTrigger>

        <button className={buttonStyles} onClick={nextDay}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <PopoverContent>
        <div className="w-full flex justify-center items-center">
          <Calendar
            selected={date.toDate()}
            // @ts-ignore
            onSelect={(date: Date) => {
              const localDate = moment(date);

              // Create a UTC date with the same year, month, and day as the local date
              const utcDate = moment.utc([
                localDate.year(),
                localDate.month(),
                localDate.date(),
              ]);

              // Convert the UTC date to Unix time
              handleDateChange(utcDate.unix());
            }}
            disabled={(date) =>
              !includeWeekend && (getDay(date) === 0 || getDay(date) === 6)
            }
            footer={
              <Button
                size="small"
                className="w-full mt-2"
                onClick={() =>
                  handleDateChange(getMidnightSeconds(moment().utc().unix()))
                }
              >
                {t("Go to today")}
              </Button>
            }
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
