import { faClock } from "@fortawesome/pro-solid-svg-icons";
import {
  dateToMomentUtc,
  getDateRangeFormat,
  useT,
  useWindowSize,
} from "@kanpla/system";
import {
  BottomDrawer,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Popper,
} from "@kanpla/ui";
import classNames from "classnames";
import { omit } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { isMobile } from "react-device-detect";
import { Calendar, CalendarProps } from "./Primitive";
import { MomentRange } from "./WeekPicker";

type CustomDateRange = {
  [key: string]: {
    label: string;
    value: MomentRange;
  };
};

export interface RangePickerProps {
  value: MomentRange | null;
  onChange: (newValue: MomentRange) => void;
  calendarProps?: Omit<
    CalendarProps,
    "mode" | "selected" | "onSelect" | "initialFocus"
  >;
  /** Adds extra content at the bottom of the Calendar */
  extraContent?: React.ReactNode;
}

const RangePicker = (props: RangePickerProps) => {
  const { value, onChange, calendarProps = {}, extraContent = null } = props;

  const t = useT();
  const { width: screenWidth } = useWindowSize();
  const mobile = isMobile || (screenWidth || Infinity) < 768;

  // Control open state or the `Popper`
  const [open, setOpen] = useState<boolean>(false);

  // Control state for desktop or mobile view
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [desktopOpen, setDesktopOpen] = useState<boolean>(false);

  // Key of predetermined cases (e.g. this week, next week...)
  const [selectedRange, setSelectedRange] = useState<string>("");
  const [customRange, setCustomRange] = useState<DateRange | undefined>({
    from: value?.[0]?.toDate() || undefined,
    to: value?.[1]?.toDate() || undefined,
  });

  const customRangeLabel =
    selectedRange === "custom" && customRange?.from && customRange?.to
      ? getDateRangeFormat({
          startDate: dateToMomentUtc(customRange.from).unix(),
          endDate: dateToMomentUtc(customRange.to).unix(),
          fullMonth: false,
        })
      : t("Custom");

  useEffect(() => {
    if (!customRange) return;

    onChange([
      dateToMomentUtc(customRange?.from as Date).startOf("day"),
      dateToMomentUtc(customRange?.to as Date).endOf("day"),
    ]);
  }, [customRange]);

  const ranges: CustomDateRange = {
    thisWeek: {
      label: t("This week"),
      value: [moment().utc().startOf("week"), moment().utc().endOf("week")],
    },
    lastWeek: {
      label: t("Last week"),
      value: [
        moment().utc().subtract(1, "week").startOf("week"),
        moment().utc().subtract(1, "week").endOf("week"),
      ],
    },
    thisMonth: {
      label: t("This month"),
      value: [moment().utc().startOf("month"), moment().utc().endOf("month")],
    },
    lastMonth: {
      label: t("Last month"),
      value: [
        moment().utc().subtract(1, "month").startOf("month"),
        moment().utc().subtract(1, "month").endOf("month"),
      ],
    },
    upcoming: {
      label: t("Upcoming"),
      value: [moment(), moment().add(90, "days")],
    },
    custom: {
      label: customRangeLabel,
      value: [moment(), moment()],
    },
  };

  const DefaultRangesMenu = (
    <Popper
      open={open}
      setOpen={setOpen}
      fitActionElement
      withPadding={false}
      margin={2}
      placement="bottom-start"
      actionElement={
        <Button icon={faClock} className="w-fit py-1">
          {ranges[selectedRange]?.label || ranges["upcoming"].label}
        </Button>
      }
    >
      <div className="p-2 relative">
        {Object.entries(omit(ranges, "custom")).map(
          ([key, { label, value }], i) => (
            <div
              key={i}
              className="px-3 py-1 w-full rounded-md hover:bg-secondary-light transition-all ease-in-out hover:text-secondary-contrast cursor-pointer my-1"
              onClick={() => {
                setSelectedRange(key);
                setOpen(false);
                onChange(value);
              }}
            >
              <h1>{label}</h1>
            </div>
          )
        )}
        <Popover
          open={desktopOpen}
          onOpenChange={(openChange) => {
            if (mobile) {
              setSelectedRange("custom");
              setOpen(false);
              return setMobileOpen(true);
            }

            setDesktopOpen(openChange);
            setSelectedRange("custom");
          }}
        >
          <PopoverTrigger className="w-full">
            <div
              key={"custom"}
              className={classNames(
                "px-3 py-1 w-full text-left rounded-md hover:bg-secondary-light transition-all ease-in-out hover:text-secondary-contrast cursor-pointer my-1",
                desktopOpen && "bg-secondary-light text-secondary-contrast"
              )}
            >
              <h1>{t("Custom")}</h1>
            </div>
          </PopoverTrigger>

          <PopoverContent
            className="z-max flex justify-center items-center"
            side="left"
            align="start"
            alignOffset={-10}
          >
            <div className="flex flex-col items-center justify-center pb-2">
              <Calendar
                initialFocus
                mode="range"
                selected={customRange}
                onSelect={setCustomRange}
                {...calendarProps}
              />
              {extraContent}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </Popper>
  );

  return (
    <>
      {DefaultRangesMenu}
      <BottomDrawer
        open={mobileOpen}
        setOpen={setMobileOpen}
        showCloseButton
        actions={[
          {
            type: "primary",
            onClick: () => setMobileOpen(false),
            label: t("Confirm"),
          },
        ]}
      >
        <div className="flex flex-col items-center justify-center">
          <Calendar
            initialFocus
            mode="range"
            selected={customRange}
            onSelect={setCustomRange}
          />
          {extraContent}
        </div>
      </BottomDrawer>
    </>
  );
};

export { RangePicker };
