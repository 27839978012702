import { getWeekSeconds } from "./getWeekSeconds";

export const getNextWorkingDay = (
  seconds: number,
  includeWeekend?: boolean
) => {
  let newSeconds = seconds + 86400; // jump to tomorrow

  const mondaySeconds = getWeekSeconds(newSeconds);
  const secondsFromMonday = newSeconds - mondaySeconds;

  //  Jump to next week if its Friday or later
  const fiveDaysInSeconds = 86400 * (includeWeekend ? 7 : 5);
  if (secondsFromMonday >= fiveDaysInSeconds)
    newSeconds = mondaySeconds + 604800;

  return newSeconds;
};
