import { Input } from "./Input";
import { Calendar as CalendarPrimitive } from "./Primitive";
import { RangePicker } from "./RangePicker";
import { WeekPicker } from "./WeekPicker";

type CompoundedCalendarType = typeof CalendarPrimitive & {
  WeekPicker: typeof WeekPicker;
  RangePicker: typeof RangePicker;
  Input: typeof Input;
};

(CalendarPrimitive as CompoundedCalendarType).WeekPicker = WeekPicker;
(CalendarPrimitive as CompoundedCalendarType).RangePicker = RangePicker;
(CalendarPrimitive as CompoundedCalendarType).Input = Input;

export const Calendar = CalendarPrimitive as CompoundedCalendarType;
