import {
  TIME_SEPARATOR,
  getAvailableTimeSlots,
  getHours,
  getMinutes,
  getTimeOption,
  useT,
} from "@kanpla/system";
import { OrderInfo, Plugins } from "@kanpla/types";
import { Select } from "@kanpla/ui";
import moment from "moment";
import { FC, default as React, useCallback, useEffect } from "react";

type TimeInputType = OrderInfo["timeInput"];

export interface TimeSelectProps {
  interval: Plugins.MinuteInterval;
  endAt: number;
  startAt: number;
  value?: TimeInputType;
  onChange?: (newValue: TimeInputType) => void;
  /** Text that appears if no text is selected */
  defaultText?: string;
  /** Should use takeaway plugin, and is the information about today? */
  takeaway?: boolean;
}

export const TimeSelect: FC<TimeSelectProps> = ({
  onChange = () => null,
  value,
  interval = 30,
  startAt = 27000,
  endAt = 57600,
  defaultText = "Vælg tid",
  takeaway = false,
}) => {
  const t = useT();
  const selectedTimeOption = value
    ? getTimeOption(getHours(value), getMinutes(value))
    : defaultText;

  const availableOptions: string[] = getAvailableTimeSlots(
    startAt,
    endAt,
    interval,
    takeaway
  );

  const updateTime = useCallback(
    (newValue: string) => {
      if (!newValue) {
        onChange(undefined);
        return;
      }

      const [h, m] = newValue.split(TIME_SEPARATOR).map((item) => Number(item));
      const newTime = h * 3600 + m * 60;

      if (value === newTime) return;

      const validTime =
        (newTime < startAt && startAt) || (newTime > endAt && endAt) || newTime;

      onChange(validTime);
    },
    [endAt, startAt, value, onChange]
  );

  useEffect(() => {
    if (value) {
      // Update if time is too late
      if (takeaway) {
        const [hours, minutes] = availableOptions?.[0]?.split(":") || [];
        const firstAvailableSeconds =
          parseInt(hours) * 3600 + parseInt(minutes) * 60;

        if (firstAvailableSeconds > value) {
          updateTime(availableOptions?.[0]);
          return;
        }
      }

      updateTime(selectedTimeOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    ...(takeaway
      ? [{ label: t("As soon as possible"), value: moment().format("hh : mm") }]
      : []),
    ...availableOptions.map((timeOpt) => ({ value: timeOpt, label: timeOpt })),
  ];

  return (
    <div
      className="w-full h-full relative flex items-center text-text-primary flex-1"
      id="time-picker-wrapper"
    >
      <Select
        className="w-full"
        value={selectedTimeOption}
        onChange={(e: string) => updateTime(e)}
        isClearable
        id="time-picker-wrapper-select"
        options={options}
      />
    </div>
  );
};
