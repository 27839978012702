import moment from "moment";

interface DateRangeFormatArgs {
  startDate: number;
  endDate: number;
  fullMonth?: boolean;
}

export const getDateRangeFormat = ({
  startDate,
  endDate,
  fullMonth = true,
}: DateRangeFormatArgs) => {
  const startDateMoment = moment.unix(startDate).utc();
  const endDateMoment = moment.unix(endDate).utc();

  const monthFormat = fullMonth ? " MMMM" : "/MM";

  if (startDateMoment.isSame(endDateMoment, "month"))
    return (
      startDateMoment.format("DD") +
      endDateMoment.format(`-DD${monthFormat} YYYY`)
    );

  return (
    startDateMoment.format(`DD${monthFormat}`) +
    endDateMoment.format(` - DD${monthFormat} YYYY`)
  );
};
