import { tx } from "@kanpla/system";
import { useEffect, useState } from "react";

type Locales = "da" | "no" | "en" | "nl" | "sv";

const localeMapping: Record<Locales | "default", string> = {
  da: "da",
  en: "en-US",
  nl: "nl",
  sv: "sv",
  no: "nb",
  default: "en-US",
};

const loadLocale = (locale: Locales): Promise<any> => {
  return new Promise((resolve) => {
    import(
      `date-fns/locale/${
        localeMapping?.[locale] || localeMapping?.["default"]
      }/index.js`
    ).then((localeModule) => {
      resolve(localeModule.default);
    });
  });
};

export const useCalendarLocale = () => {
  const [locale, setLocale] = useState();
  const currentLocale = tx.getCurrentLocale();

  useEffect(() => {
    const fetchLocale = async () => {
      const loadedLocale = await loadLocale(currentLocale);
      setLocale(loadedLocale);
    };

    fetchLocale();
  }, [currentLocale]);

  return { locale };
};
