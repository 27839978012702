import { faCalendar } from "@fortawesome/pro-solid-svg-icons";
import { dateToMomentUtc, useT, useWindowSize } from "@kanpla/system";
import classNames from "classnames";
import { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../elements/Popover";
import { Input as TextInput } from "../Input";
import { Calendar, CalendarProps } from "./Primitive";
import { MomentRange } from "./WeekPicker";

interface InputProps {
  /** Class names applied to the wrapper of the input */
  className?: string;
  /** Popover classname */
  popoverClassName?: string;
  /** Mode of the Calendar, either single day pick or range. Defaults to `single` */
  mode?: "single" | "range";
  /** Value of the input */
  value?: Moment | MomentRange | undefined;
  /** Callback when the value changes */
  onChange?: (value: Moment | MomentRange) => void;
}

type CalendarInputProps = InputProps &
  Omit<CalendarProps, "mode" | "selected" | "onSelect">;

const Input = ({
  className = "",
  popoverClassName = "",
  mode = "single",
  value = undefined,
  onChange = () => null,
  ...rest
}: CalendarInputProps): JSX.Element => {
  const t = useT();
  const { isMobileSize } = useWindowSize();

  const convertToDefaultValue = useCallback(():
    | Date
    | DateRange
    | undefined => {
    if (!value) return value;

    if (mode === "single") return (value as Moment)?.toDate();

    return {
      from: (value as MomentRange)[0]?.toDate(),
      to: (value as MomentRange)[1]?.toDate(),
    };
  }, []);

  const [selected, setSelected] = useState<DateRange | Date | undefined>(
    convertToDefaultValue()
  );

  const inputValue = () => {
    if (!selected) return undefined;

    if (mode === "single") {
      return dateToMomentUtc(selected as Date).format("L");
    }

    if (!((selected as DateRange)?.from && (selected as DateRange)?.to))
      return undefined;

    // @ts-ignore
    return `${dateToMomentUtc((selected as DateRange)?.from).format(
      "L"
      // @ts-ignore
    )} - ${dateToMomentUtc((selected as DateRange)?.to).format("L")}`;
  };

  useEffect(() => {
    if (!selected) return;

    if (mode === "single") return onChange(dateToMomentUtc(selected as Date));

    /**
     * The default value should be an empty range
     */
    if (!(selected as DateRange)?.from && !(selected as DateRange)?.to) return;

    onChange([
      // @ts-ignore
      dateToMomentUtc((selected as DateRange)?.from),
      // @ts-ignore
      dateToMomentUtc((selected as DateRange)?.to),
    ]);
  }, [selected]);

  return (
    <Popover>
      <PopoverTrigger className={className}>
        <TextInput
          placeholder={
            mode === "range" ? t("Select a range...") : t("Select a date...")
          }
          value={inputValue()}
          icon={faCalendar}
          readOnly
          className="w-full"
        />
      </PopoverTrigger>

      <PopoverContent
        className={classNames(
          "flex justify-center items-center !w-screen sm:!w-full",
          popoverClassName
        )}
        alignOffset={1}
        sideOffset={isMobileSize ? -85 : 0}
      >
        {/* @ts-ignore */}
        <Calendar
          mode={mode}
          selected={selected}
          onSelect={setSelected}
          {...rest}
        />
      </PopoverContent>
    </Popover>
  );
};

export { Input };
